<template>
  <div>
    <!--Begin::LIST USERS-->
    <KTPortlet>
      <template v-slot:toolbar></template>
      <template v-slot:body>
        <ShowUsers
          :key="offersKey"
          @reset="offersKey += 1"
          table-id="users"
        >
          <template slot="title">{{ $t("USERS.SUB_TITLE") }}</template>
        </ShowUsers>
      </template>
    </KTPortlet>
    <!--End::LIST USERS-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ShowUsers from "@/views/pages/users/ShowUsers.vue";

export default {
  name: "Users",
  components: {
    KTPortlet,
    ShowUsers,
  },
  data() {
    return {
      offersKey: 0,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("USERS.TITLE") },
    ]);
  },
  methods: {},
};
</script>
