<template>
  <v-card >
    <v-tabs v-model="tab" class="deep-gl" left dark icons-and-text>
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        <v-toolbar-title>
          <slot name="title"></slot>
        </v-toolbar-title>
      </v-tab>
      <v-spacer></v-spacer>

      <div class="buttons-tab">
        <v-btn color="mr-4" v-on:click="createUser()">Add New User</v-btn>
      </div>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-card flat>
          <v-card-text>
            <Table
              :path="path"
              :tableId="tableId"
              dbTable="users"
              :tableInit="tableInit"
              :showParams="showParams"
              :filtersFields="filtersFields"
            ></Table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { TABLE_INIT_URL, GET_USERS_INIT } from "@/store/users.module";
import Table from "@/views/partials/table/Table.vue";

export default {
  name: "ShowUser",
  components: {
    Table,
  },
  props: ["tableId"],
  mounted() {},
  data() {
    return {
      isProcessing: false,
      path: TABLE_INIT_URL,
      tableInit: GET_USERS_INIT,
      tab: "tab-1",
      showParams: { name: "edit-user", key: "id" },
      filtersFields: [],
    };
  },
  methods: {
    createUser() {
      this.$router.push({ name: "new-user"});
    },
  },
};
</script>

<style scoped>
.tag:not(body).is-danger {
  background-color: none;
}
</style>

